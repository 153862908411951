exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-doors-js": () => import("./../../../src/pages/doors.js" /* webpackChunkName: "component---src-pages-doors-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-gallery-js": () => import("./../../../src/pages/en/gallery.js" /* webpackChunkName: "component---src-pages-en-gallery-js" */),
  "component---src-pages-en-greener-homes-initiative-2024-for-windows-and-doors-canada-js": () => import("./../../../src/pages/en/greener-homes-initiative-2024-for-windows-and-doors-canada.js" /* webpackChunkName: "component---src-pages-en-greener-homes-initiative-2024-for-windows-and-doors-canada-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-js": () => import("./../../../src/pages/en/privacy.js" /* webpackChunkName: "component---src-pages-en-privacy-js" */),
  "component---src-pages-en-products-doors-js": () => import("./../../../src/pages/en/products/doors.js" /* webpackChunkName: "component---src-pages-en-products-doors-js" */),
  "component---src-pages-en-products-js": () => import("./../../../src/pages/en/products.js" /* webpackChunkName: "component---src-pages-en-products-js" */),
  "component---src-pages-en-products-patio-doors-js": () => import("./../../../src/pages/en/products/patio-doors.js" /* webpackChunkName: "component---src-pages-en-products-patio-doors-js" */),
  "component---src-pages-en-products-windows-js": () => import("./../../../src/pages/en/products/windows.js" /* webpackChunkName: "component---src-pages-en-products-windows-js" */),
  "component---src-pages-en-service-area-laval-js": () => import("./../../../src/pages/en/service-area/laval.js" /* webpackChunkName: "component---src-pages-en-service-area-laval-js" */),
  "component---src-pages-en-service-area-montreal-east-js": () => import("./../../../src/pages/en/service-area/montreal-east.js" /* webpackChunkName: "component---src-pages-en-service-area-montreal-east-js" */),
  "component---src-pages-en-service-area-montreal-js": () => import("./../../../src/pages/en/service-area/montreal.js" /* webpackChunkName: "component---src-pages-en-service-area-montreal-js" */),
  "component---src-pages-en-service-area-north-york-js": () => import("./../../../src/pages/en/service-area/north-york.js" /* webpackChunkName: "component---src-pages-en-service-area-north-york-js" */),
  "component---src-pages-en-service-area-south-shore-js": () => import("./../../../src/pages/en/service-area/south-shore.js" /* webpackChunkName: "component---src-pages-en-service-area-south-shore-js" */),
  "component---src-pages-en-service-area-west-island-js": () => import("./../../../src/pages/en/service-area/westIsland.js" /* webpackChunkName: "component---src-pages-en-service-area-west-island-js" */),
  "component---src-pages-en-services-js": () => import("./../../../src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-en-thank-you-js": () => import("./../../../src/pages/en/thank-you.js" /* webpackChunkName: "component---src-pages-en-thank-you-js" */),
  "component---src-pages-fr-blogue-js": () => import("./../../../src/pages/fr/blogue.js" /* webpackChunkName: "component---src-pages-fr-blogue-js" */),
  "component---src-pages-fr-confidentialite-js": () => import("./../../../src/pages/fr/confidentialite.js" /* webpackChunkName: "component---src-pages-fr-confidentialite-js" */),
  "component---src-pages-fr-contactez-nous-js": () => import("./../../../src/pages/fr/contactez-nous.js" /* webpackChunkName: "component---src-pages-fr-contactez-nous-js" */),
  "component---src-pages-fr-galerie-js": () => import("./../../../src/pages/fr/galerie.js" /* webpackChunkName: "component---src-pages-fr-galerie-js" */),
  "component---src-pages-fr-greener-homes-initiative-2024-for-windows-and-doors-canada-js": () => import("./../../../src/pages/fr/greener-homes-initiative-2024-for-windows-and-doors-canada.js" /* webpackChunkName: "component---src-pages-fr-greener-homes-initiative-2024-for-windows-and-doors-canada-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-merci-js": () => import("./../../../src/pages/fr/merci.js" /* webpackChunkName: "component---src-pages-fr-merci-js" */),
  "component---src-pages-fr-produits-fenetres-js": () => import("./../../../src/pages/fr/produits/fenetres.js" /* webpackChunkName: "component---src-pages-fr-produits-fenetres-js" */),
  "component---src-pages-fr-produits-js": () => import("./../../../src/pages/fr/produits.js" /* webpackChunkName: "component---src-pages-fr-produits-js" */),
  "component---src-pages-fr-produits-portes-js": () => import("./../../../src/pages/fr/produits/portes.js" /* webpackChunkName: "component---src-pages-fr-produits-portes-js" */),
  "component---src-pages-fr-produits-portes-patio-js": () => import("./../../../src/pages/fr/produits/portes-patio.js" /* webpackChunkName: "component---src-pages-fr-produits-portes-patio-js" */),
  "component---src-pages-fr-service-area-laval-js": () => import("./../../../src/pages/fr/service-area/laval.js" /* webpackChunkName: "component---src-pages-fr-service-area-laval-js" */),
  "component---src-pages-fr-service-area-montreal-east-js": () => import("./../../../src/pages/fr/service-area/montreal-east.js" /* webpackChunkName: "component---src-pages-fr-service-area-montreal-east-js" */),
  "component---src-pages-fr-service-area-montreal-js": () => import("./../../../src/pages/fr/service-area/montreal.js" /* webpackChunkName: "component---src-pages-fr-service-area-montreal-js" */),
  "component---src-pages-fr-service-area-north-york-js": () => import("./../../../src/pages/fr/service-area/north-york.js" /* webpackChunkName: "component---src-pages-fr-service-area-north-york-js" */),
  "component---src-pages-fr-service-area-south-shore-js": () => import("./../../../src/pages/fr/service-area/south-shore.js" /* webpackChunkName: "component---src-pages-fr-service-area-south-shore-js" */),
  "component---src-pages-fr-service-area-west-island-js": () => import("./../../../src/pages/fr/service-area/westIsland.js" /* webpackChunkName: "component---src-pages-fr-service-area-west-island-js" */),
  "component---src-pages-fr-services-js": () => import("./../../../src/pages/fr/services.js" /* webpackChunkName: "component---src-pages-fr-services-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-greener-homes-initiative-2024-for-windows-and-doors-canada-js": () => import("./../../../src/pages/greener-homes-initiative-2024-for-windows-and-doors-canada.js" /* webpackChunkName: "component---src-pages-greener-homes-initiative-2024-for-windows-and-doors-canada-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patio-doors-js": () => import("./../../../src/pages/patio-doors.js" /* webpackChunkName: "component---src-pages-patio-doors-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-service-area-laval-js": () => import("./../../../src/pages/service-area/laval.js" /* webpackChunkName: "component---src-pages-service-area-laval-js" */),
  "component---src-pages-service-area-montreal-east-js": () => import("./../../../src/pages/service-area/montreal-east.js" /* webpackChunkName: "component---src-pages-service-area-montreal-east-js" */),
  "component---src-pages-service-area-montreal-js": () => import("./../../../src/pages/service-area/montreal.js" /* webpackChunkName: "component---src-pages-service-area-montreal-js" */),
  "component---src-pages-service-area-north-york-js": () => import("./../../../src/pages/service-area/north-york.js" /* webpackChunkName: "component---src-pages-service-area-north-york-js" */),
  "component---src-pages-service-area-south-shore-js": () => import("./../../../src/pages/service-area/south-shore.js" /* webpackChunkName: "component---src-pages-service-area-south-shore-js" */),
  "component---src-pages-service-area-west-island-js": () => import("./../../../src/pages/service-area/westIsland.js" /* webpackChunkName: "component---src-pages-service-area-west-island-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-windows-js": () => import("./../../../src/pages/windows.js" /* webpackChunkName: "component---src-pages-windows-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */)
}

